<template>
  <div v-if="(!isAgentLogin&&isMobile)||isAgentLogin" :class="[{'is-dark': scheduleOptions.agentScheduleDarkView==true}, 'mobile-nav-footer']" >
    <MobileNavbarItem
      v-if="!isAgentLogin"
      :label="$t('navigation.portfolio')"
      icon="portfolio_outline"
      @click="goto('portfolio')"
      :class="
        (isPage('portfolio') && 'is-active') || (isPage('node') && 'is-active')
      "
    />
    <MobileNavbarItem
      v-if="isAgentLogin"
      :label="$t('navigation.schedule')"
      icon="clock"
      :isDark="this.isDark"
      @click="goto('agenthomescr')"
      :class="isPage('agenthomescr') && 'is-active'"
    />
       <!--v-if checks both canSeeTeam and isAgentLogin-->
    <MobileNavbarItem
      v-if="canSeeTeam && isAgentLogin"
      :label="$t('navigation.team')"
      icon="grid"
      :isDark="this.isDark"
      @click="canSeeTeam && goto('team-schedule')"
      :class="isPage('team-schedule') && 'is-active'"
    />

   <!--v-if checks both canTrade and isAgentLogin-->
    <MobileNavbarItem
      v-if="canTrade && isAgentLogin"
      :label="$t('navigation.trade')"
      icon="trade"
      :isDark="this.isDark"
      @click="canTrade && goto('trade')"
      :class="isPage('trade') && 'is-active'"
      
    />
    <MobileNavbarItem
      v-if="canSeeStats && isAgentLogin"
      :label="$t('navigation.agent-stats')"
      icon="stats"
      :isDark="this.isDark"
      @click="goto('agent-stats')"
      :class="isPage('agent-stats') && 'is-active'"
    />
    <MobileNavbarItem
    v-if="showForecast"
    :label="$t('navigation.forecast')"
      icon="compare_outline"
      @click="goto('forecast')"
      :class="isPage('forecast') && 'is-active'"
    />
    <MobileNavbarItem
      v-if="showSchedule"
      :label="$t('navigation.schedule')"
      icon="compare_outline"
      @click="goto('schedule')"
      :class="isPage('schedule') && 'is-active'"
    />
    <MobileNavbarItem
      v-if="showReport"
      :label="$t('navigation.report')"
      icon="compare_outline"
      @click="goto('report')"
      :class="isPage('report') && 'is-active'"
    />
    <MobileNavbarItem
      v-if="showSettings"
      :label="$t('navigation.settings')"
      icon="compare_outline"
      @click="goto('settings')"
      :class="isPage('settings') && 'is-active'"
    />
    <MobileNavbarItem
      v-if="isAgentLogin"
      :label="$t('navigation.user')"
      icon="user"
      :isDark="this.isDark"
      @click="goto('user')"
      :class="isPage('user') && 'is-active'"
    />

    
  </div>
</template>

<script>
import MobileNavbarItem from "./MobileNavbarItem.vue";
import * as dn_uiHelper from "@/js/dn-uihelper.js";
import { useDataStore } from "@/stores/dataStore.js";
import { useScheduleStore } from "@/stores/scheduleStore.js";

export default {
  // @ts-ignore
  name: "MobileNavbarFooter",
  components: { MobileNavbarItem},
  data() {
    return {
      isMobile:false,
    };
  },
  computed: {
    scheduleOptions() { return useScheduleStore().scheduleOptions; },
    isDark() { return useScheduleStore().scheduleOptions.agentScheduleDarkView; },
    currentCC() { return useDataStore().currentCC; },
    currentUser() { return useDataStore().currentUser; },
    canTrade()  { return !useDataStore().generalEmpAppSettings.tradeDisabled;},
    //!useDataStore().generalEmpAppSettings.hideTeamSchedule
    canSeeTeam: function() {
       return !useDataStore().generalEmpAppSettings.hideTeamSchedule;
      },
    canSeeStats: function() {
      const gs=useDataStore().generalEmpAppSettings
       return gs.statsAdherence||gs.statsPayrollCodes||gs.statsDetailedAdherence;
      },

    isAgentLogin: function()  {
      return this.currentUser != null && this.currentUser.isAgent;
    },
    isCurrentCCSelected: function () {
      return this.currentCC != null;
    },
    showForecast() {
      return this.currentUser !== null && this.currentUser.hasAccessForecasting;
    },
    showReport() {
      return this.currentUser !== null && this.currentUser.hasAccessReports && this.isCurrentCCSelected;
    },
    showSchedule() {
      return this.currentUser !== null && this.currentUser.hasAccessScheduling && this.isCurrentCCSelected;
    },
    showSettings() {
      return this.currentUser !== null && this.currentUser.hasAccessSettings;
    }
  },
  methods: {
    goto(name) {
      console.log(name);
      this.$router.push({ name });
    },
    isPage: function (page) {
      return this.$route.name == page;
    },
  },
  created() { this.isMobile = dn_uiHelper.isMobile();},
  mounted() { },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.module.scss";

.mobile-nav-footer {
  max-width: $agentDesktop-max-width;
  position: fixed; 
  width: 100%;
  display: flex;
  height: 85px;
  border-top: 1px solid $dark;
  align-items: center;
  justify-content: space-around;
  background: white;
  z-index: 2;
  bottom: 0;
  &.is-dark {
    background: black;
    border-top: 1px solid $dark;
  }

  .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: relative;
    width: 100%;

    &.is-active {
      &:after {
        content: "";
        border-bottom: 4px solid;
        color: $primary;
        width: 100%;
        height: 3px;
        display: block;
        margin-top: 7px;
        position: absolute;
        bottom: 0px;
      }
    }
  }
}
// @media screen and (min-width: $mobileBreakPoint) {
//   .mobile-nav-footer {
//     display: none;
//   }
// }
</style>
