import { addDays, getShortDate, getStartOfDay, getStartOfWeek } from '@/js/dn-helper.js';

/**
 * @param {Date} nowDt
 * @param {{ forecastWeeks: number; historicFocusDate:null|Date; historicWeeks: number; occupacyFte: number; }} forecastOptions
 * @param {Map<string, number>} specialDays
 * @param {Map<string, {acgr:number, dt:Date, ahtData:number[], nocData:number[]}>} historicDataMap
 * @param {{id:number}[]} callGroups
 * @param {{id:number}[]} dayTypes
 */
export function getAllWeekData(nowDt,forecastOptions,specialDays,historicDataMap,callGroups, dayTypes) {
  const returnData = new Map();
  let startDate=new Date()
 if(forecastOptions.historicFocusDate!==null){
    startDate = getStartOfWeek(addDays(new Date(forecastOptions.historicFocusDate), 0)); /* mon or sun of the youngset week */
 }else{
    startDate = getStartOfWeek(addDays(new Date(nowDt), forecastOptions.forecastWeeks * 7)); /* mon or sun of the youngset week */
 }


  for (let a = 0; a < callGroups.length; a++) {
    let weekMap = new Map();
    /* NORMAL DAYS including special days during normal period*/
    for (let w = 0; w < forecastOptions.historicWeeks + forecastOptions.forecastWeeks; w++) {
      let dtweekStart = getStartOfWeek(addDays(startDate, 1 - w * 7));

      let wArray = []; /* 7 days */
      for (let d = 0; d <= 6; d++) {
        let dtx = addDays(dtweekStart, d);
        wArray.push(getDayData(callGroups[a].id, dtx, forecastOptions.occupacyFte,historicDataMap));
      }
      weekMap.set(getShortDate(dtweekStart), wArray);
    }

    /* SPECIAL DAYS*/
    if(forecastOptions.historicFocusDate===null){
      for (let dx = 1; dx < dayTypes.length; dx++) {
        let itr = historicDataMap.keys();
        for (let i = 0; i < historicDataMap.size; i++) {
          let key = itr.next().value;
          let histData =historicDataMap.get(key);

          if (specialDays.has(getShortDate(histData.dt))) {
            if (histData.acgr == callGroups[a].id && specialDays.get(getShortDate(histData.dt)) == dayTypes[dx].id) {
              let dt = new Date(histData.dt);
              let wArray = [];
              let dtweekStart = getStartOfWeek(dt);
              let dtkey = getShortDate(dtweekStart);
              if (weekMap.has(dtkey)) {
                wArray = weekMap.get(dtkey);
              } else {
                for (let d = 0; d <= 6; d++) {
                  wArray.push({ weekdayindex: d, dt: null, fte: 0, ffte: 0, noc: 0, fnoc: 0, aht: 0, faht: 0, nocRms: 8, dayType: 0, isChecked: false });
                }
              }
              wArray[dt.getDay()] = getDayData(callGroups[a].id, dt, forecastOptions.occupacyFte,historicDataMap);
              weekMap.set(dtkey, wArray);
            }
          }
        }
      }
    }

    /* Transfer data from weekMap to weeklists */
    let itr2 = weekMap.keys();
    let weekList = []; /* One row per week */
    for (let i = 0; i < weekMap.size; i++) {
      let key = itr2.next().value;
      let val = weekMap.get(key);
      let dt = new Date(key);
      let weeknumber = dt.getWeekNumber();

      if (new Date(dt).getFullYear() != nowDt.getFullYear()) {
        weeknumber = dt.getFullYear() + ' ' + dt.getWeekNumber();
      }

      weekList.push({ week: 'w ' + weeknumber, weekdata: val });
    }
    let key = callGroups[a].id;
    returnData.set(key, weekList);
  }
  return returnData;
}
export function updateForecastInAllWeekData(callStatsWeekMap, nowDt, forecastOptions, specialDaysMap, forecastAdjustmentmap,historicDataMap,aiForecastMap,callGroups, latestImportDate) {
  let focusDt =nowDt
  if(forecastOptions.historicFocusDate!==null){ focusDt = forecastOptions.historicFocusDate  }
  for (let a = 0; a < callGroups.length; a++) {
    let key = callGroups[a].id;
    let weekList = callStatsWeekMap.get(key);
    for (let w = 0; w < weekList.length; w++) {
      for (let d = 0; d < 7; d++) {
        let dayData = weekList[w].weekdata[d];
        const dt = new Date(dayData.dt);
        const forc = getForcastXX(dt, focusDt, specialDaysMap, forecastOptions.historicWeeks, callGroups[a], historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate);
        dayData.fnoc = parseInt(forc.noc.reduce((a, b) => a + b, 0));
        if (dayData.fnoc > 0) {
          dayData.faht = forc.ht.reduce((a, b) => a + b, 0) / dayData.fnoc;
          dayData.ffte = (dayData.faht * dayData.fnoc) / (96*900*(forecastOptions.occupacyFte/100));
        }
        dayData.nocRms=dayData.noc*getRMS(callGroups[a].id,dt,forc.noc,historicDataMap)
      }
    }
  }
  return callStatsWeekMap;
}

function getRMS(acgr,dt,fnoc,historicDataMap){
  let keyx = acgr + '_' + dt.yyyymmdd();
  let seriesData = {};
  if (historicDataMap.has(keyx)) {
    seriesData = historicDataMap.get(keyx);
    let sq= seriesData.nocData.map(function(x, index) {return Math.pow((fnoc[index] -x),2) });
    let sqRef=seriesData.nocData.map(function(x, index) {return Math.pow((x),2) });
    let refRMS =Math.sqrt(sqRef.reduce((a, b) => a + b, 0) / 96)
    let RMS =Math.sqrt(sq.reduce((a, b) => a + b, 0) / 96)
    if(refRMS>0){return 100*RMS/refRMS;}
  }
  return 0
}



/**
 * @param {{ selectedParameters: string[]; compareWithForecast: boolean; selectedCallGroups: {id:number;forecastMethod:number}[]; selectedDates: any[]; historicWeeks: number; occupacyFte: number}} forecastOptions
 * @param {Map<string, number>} specialDaysMap
 * @param {Date} dateNow
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; }>} historicDataMap
 * @param {import("@/stores/dn-forecast-ai-data.js").ForecastDataMap} aiForecastMap
 * @param {Date} latestImportDate
 */
export function getChartData(forecastOptions, specialDaysMap,dateNow,forecastAdjustmentmap,historicDataMap,aiForecastMap, latestImportDate) {
  const selectedCallGroups = forecastOptions.selectedCallGroups;
  const dates = forecastOptions.selectedDates;
  const params = forecastOptions.selectedParameters;
  let returnValue = [];

  if (params.length == 1) {
    if(dates.length == 1){
    switch (params[0]) {
      case "noc":

        if (isForecast(dates[0])) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'fnoc', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap,historicDataMap, aiForecastMap, latestImportDate));
        } else {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
          if (forecastOptions.compareWithForecast) {
            returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'fnoc', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
          }
        }
        break;
      case "aht":
      if (isForecast(dates[0])) {
        returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'faht', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
      } else {
        returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        if (forecastOptions.compareWithForecast) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'faht', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        }
      }
        break;
      case "fte":
      if (isForecast(dates[0])) {
        returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'ffte', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
      } else {
        returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        if (forecastOptions.compareWithForecast) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'ffte', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        }
      }
        break;
      default:
        console.log("in default");
    }
  }else{
    for (let i = 0; i < dates.length; i++) {
      switch (params[0]) {
        case "noc":

          if (isForecast(dates[i])) {
            returnValue.push(getChartSerie(selectedCallGroups, dates[i], 'fnoc', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
          } else {
            returnValue.push(getChartSerie(selectedCallGroups, dates[i], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
           }
          break;
        case "aht":
        if (isForecast(dates[i])) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[i], 'faht', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        } else {
          returnValue.push(getChartSerie(selectedCallGroups, dates[i], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));

        }
          break;
        case "fte":
        if (isForecast(dates[i])) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[i], 'ffte', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        } else {
          returnValue.push(getChartSerie(selectedCallGroups, dates[i], params[0], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap,aiForecastMap, latestImportDate));
        }
          break;
        default:
          console.log("in default");
      }
     }
  }
    return returnValue;
  }

  if (dates.length == 1) {
    for (let i = 0; i <params.length; i++) {
      switch (params[i]) {

        case "noc":

          if (isForecast(dates[0])) {
            returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'fnoc', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
          } else {
            returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[i], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
           }
          break;
        case "aht":
        if (isForecast(dates[0])) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'faht', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
        } else {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[i], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
         }
          break;
        case "fte":
        if (isForecast(dates[0])) {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], 'ffte', dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
        } else {
          returnValue.push(getChartSerie(selectedCallGroups, dates[0], params[i], dateNow, specialDaysMap, forecastOptions,forecastAdjustmentmap, historicDataMap, aiForecastMap,latestImportDate));
         }
          break;
        default:
          console.log("in default");
      }
    }
  }

  if(dates.length > 1&&params.length>1){
    returnValue = []
  }

  return returnValue;
  function isForecast(dt){
    return (new Date(dt) >= new Date(dateNow) || dt == dateNow)
   }
}

/**
 * @param {Date} dt
 * @param {number} length
 * @param {Date} nowDt
 * @param {Map<string, number>} specialDaysMap
 * @param {number} numberOfWeeks
 * @param {object} acgr
 * @param {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; }>} historicDataMap
 * @param {import("@/stores/dn-forecast-ai-data.js").ForecastDataMap} aiForecastMap
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {Date} latestImportDate
 */
export function getForcastOnInterval(dt, length, nowDt, specialDaysMap, numberOfWeeks, acgr, historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate) {
  const indexesPerDay = 96;
  const startOfDay = getStartOfDay(dt);
  if (length === indexesPerDay && startOfDay.getTime() === dt.getTime()) {
    return getForcastXX(dt, nowDt, specialDaysMap, numberOfWeeks, acgr, historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate);
  }

  const msPerIndex = 1000*60*15;
  const startIndex = Math.floor((dt.getTime() - startOfDay.getTime()) / msPerIndex);
  const lengthNextDay = Math.max(0, startIndex + length - indexesPerDay);
  const lengthStartDay = length - lengthNextDay;
  const forecast = getForcastXX(startOfDay, nowDt, specialDaysMap, numberOfWeeks, acgr, historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate);
  forecast.ht = forecast.ht.slice(startIndex, startIndex + lengthStartDay);
  forecast.noc= forecast.noc.slice(startIndex, startIndex + lengthStartDay);
  if (lengthNextDay > 0) {
    const nextDay = addDays(startOfDay, 1);
    const forecastNextDay = getForcastXX(nextDay, nowDt, specialDaysMap, numberOfWeeks, acgr, historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate);
    forecast.ht = forecast.ht.concat(forecastNextDay.ht.slice(0, lengthNextDay));
    forecast.noc = forecast.noc.concat(forecastNextDay.noc.slice(0, lengthNextDay));
  }

  return forecast;
}

/**
 * Calculate forecast for one given day
 * @param {Date} dt
 * @param {Date} nowDt
 * @param {Map<string, number>} specialDaysMap
 * @param {number} numberOfWeeks
 * @param {{id:number;forecastMethod:number}} acgr
 * @param {Map<string, {dt:Date, acgr:number, ahtData:number[], nocData:number[]}>} historicDataMap
 * @param {import("@/stores/dn-forecast-ai-data.js").ForecastDataMap} aiForecastMap
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {Date} latestImportDate
 */
export function getForcastXX(dt, nowDt, specialDaysMap, numberOfWeeks, acgr, historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate) {
  const adjnoc = getAdj(dt, acgr.id, forecastAdjustmentmap);
  let daytype = getDaytype(dt, specialDaysMap);
  if (!latestImportDate) { latestImportDate = nowDt; }

    /** @type {number[]}} */
    let nocSum = new Array(96).fill(0);
    /** @type {number[]}} */
  let htSum = new Array(96).fill(0);

  if(acgr.forecastMethod==1){
    let keyx = acgr.id + '_' + getShortDate(dt);
    if(aiForecastMap.has(keyx)){
      let x = aiForecastMap.get(keyx)
      nocSum = x.nocData
      for (let i = 0; i < 96; i++) {
        htSum[i]= x.ahtData[i] * x.nocData[i]
      }
    }

  }else{

 

  //future days same forecast as earliest future day
  if (dt.getTime() >= nowDt.getTime()) {
    let weekOffset = Math.floor((dt.getTime() - nowDt.getTime()) / (60 * 60 * 24 * 1000 * 7));
    dt = addDays(dt, -weekOffset * 7);
  }
  let numberOfDysCounter = 0;

 
  if (daytype == 0) {
    for (let wx = 1; wx < numberOfWeeks + 1; wx++) {
      let dtx = addDays(new Date(dt), -wx * 7);
      let keyx = acgr.id + '_' + getShortDate(dtx);
      let histData = {};

    

      //exclude days that are not complete
      if (historicDataMap.has(keyx)&&dtx<=addDays(latestImportDate, -1)) {
        histData = historicDataMap.get(keyx);
        let daytypex = getDaytype(dtx, specialDaysMap);
        if(histData.nocData.reduce((a, b) => a + b, 0)>0){
        if (daytypex == 0) {
          nocSum = nocSum.map(function(x, index) {
            return histData.nocData[index] + x;
          });
          htSum = htSum.map(function(x, index) {
            return histData.nocData[index] * histData.ahtData[index] + x;
          });
          numberOfDysCounter++;
        }

        //console.log('Hist '+ histData.nocData.reduce((a, b) => a + b, 0))
        //console.log('Forc' + nocSum.reduce((a, b) => a + b, 0))
        }
      }
    }
  } else {
    let itr = historicDataMap.keys();
    for (let i = 0; i < historicDataMap.size; i++) {
      let key = itr.next().value;
      let histData = historicDataMap.get(key);
      let dtx = histData.dt;
      if (dtx < dt) {
        let daytypex = getDaytype(dtx, specialDaysMap);

        if (histData.acgr == acgr.id && daytypex == daytype) {
          if(histData.nocData.reduce((a, b) => a + b, 0)>0){
          nocSum = nocSum.map(function(x, index) {
            return histData.nocData[index] + x;
          });
          htSum = htSum.map(function(x, index) {
            return histData.nocData[index] * histData.ahtData[index] + x;
          });
          numberOfDysCounter++;
        }
        }
      }
    }
  }

/*
let isDemo=false
  let loginInfo = dnLocalStorage.getLoginInfo();
  if(loginInfo && loginInfo.customerSchema == 'demo'){isDemo=true}
  if (numberOfDysCounter > 0) {
    nocSum = nocSum.map(function(x) {
      if(isDemo){return 1*x / numberOfDysCounter;}else{return x / numberOfDysCounter;}
    });
    htSum = htSum.map(function(x) {
      if(isDemo){return 1*x / numberOfDysCounter;}else{return x / numberOfDysCounter;}
    });
  } */

//*************************************************************************** */
  if (numberOfDysCounter > 0) {
    nocSum = nocSum.map(function(x) {
      return x / numberOfDysCounter;
    });
    htSum = htSum.map(function(x) {
         return x / numberOfDysCounter;
    });
  }

}
  //*************************************************************************** */

  if (adjnoc!=0) {
     return adjustSeries(htSum,nocSum, adjnoc)
  }

/*   nocSum = new Array(96).fill(0)
  htSum = new Array(96).fill(0*300)

  nocSum[48] = 5
  htSum[48] = 5*300 */

  return { noc: nocSum, ht: htSum };
}

function adjustSeries(ht,noc, adj) {
  let nocAdj = []
  let s = noc.reduce((a, b) => a + b, 0);
  if (s != 0) {
    nocAdj = noc.map(function(x,index) {return x+ (x * adj/ s)});
    ht = ht.map(function(x,index) {return noc[index]>0 ? x*nocAdj[index]/noc[index]:0});
  }
  return  { noc: nocAdj, ht: ht };
}

/**
 * @param {string} parameter
 */
export function getForecastSetValue(parameter) {
  let returnValue = { setValue: 0, minimum: 0, maximum: 0 };
  switch (parameter) {
    case 'SL':
      returnValue = { setValue: 80, minimum: 70, maximum: 90 };
      break;
    default:
      console.log('in default');
  }
  return returnValue;
}

/**
 * @param {Date} dt
 * @param {number} acgr
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 */
function getAdj(dt, acgr, forecastAdjustmentmap) {
  let adj = 0;
  const day = getShortDate(dt);
  if (forecastAdjustmentmap.has(day)) {
    let adjMap = forecastAdjustmentmap.get(day);
    if (adjMap.has(acgr)) {
      adj = adjMap.get(acgr);
    }
  }
  return adj;
}

/**
 * @param {{id:number;forecastMethod:number}[]} selectedCallGroups
 * @param {string | number | Date} dt
 * @param {string} param
 * @param {Date} nowDt
 * @param {Map<string, number>} specialDaysMap
 * @param {{ historicWeeks: number; occupacyFte: number; }} forecastOptions
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; }>} historicDataMap
 * @param {import("@/stores/dn-forecast-ai-data.js").ForecastDataMap} aiForecastMap
 * @param {Date} [latestImportDate]
 */
export function getChartSerie(selectedCallGroups, dt, param, nowDt, specialDaysMap, forecastOptions, forecastAdjustmentmap,historicDataMap,aiForecastMap, latestImportDate) {

  let shortDate = getShortDate(new Date(dt));

  let nocArray = Array(96).fill(0);
  let htArray = Array(96).fill(0);

  let returnArray = [];

  for (let a = 0; a < selectedCallGroups.length; a++) {
    let key = selectedCallGroups[a].id + '_' + shortDate;
    let nocData = [];
    let htData = [];
    if (param == 'noc' || param == 'aht' || param == 'fte') {
      let seriesData = {};
      if (historicDataMap.has(key)) {
        seriesData = historicDataMap.get(key);

      nocData = seriesData.nocData;
      htData = seriesData.ahtData.map(function(x, index) {
        return x * nocData[index];
      });
    }
    } else {
      const x = getForcastXX(new Date(dt), nowDt, specialDaysMap, forecastOptions.historicWeeks, selectedCallGroups[a], historicDataMap,aiForecastMap, forecastAdjustmentmap, latestImportDate);
      nocData = x.noc;
      htData = x.ht;
    }

    //if day is 23 hours due to day shortening
    if(nocData.length<nocArray.length){
      let diff =nocData.length-nocArray.length
      for(let q=0;q<diff;q++){
        nocData.push(0)
        htData.push(0)
      }
    }

    if (nocData != null) {
      switch (param) {
        case 'noc':
        case 'fnoc':
          nocArray = nocArray.map(function(x, index) {
            return nocData[index] + x;
          });
          break;
        default:
          nocArray = nocArray.map(function(x, index) {
            return nocData[index] + x;
          });
          htArray = htArray.map(function(x, index) {
            return htData[index] + x;
          });
          break;
      }
    }
  }

  let scaleindex=0;
  switch (param) {
    case 'noc':
    case 'fnoc':
      scaleindex=0;
      returnArray = nocArray;
      break;
    case 'faht':
    case 'aht':
      scaleindex=1;
      returnArray = nocArray.map(function(x, index) {
        return x>0? (htArray[index] / x):0;
      });
      break;
    case 'fte':
    case 'ffte':
      scaleindex=2;
      returnArray = htArray.map(function(x) {
        return x / (900*(forecastOptions.occupacyFte/100));
      });
      break;
    default:
      break;
  }

  return {
    label: param,
    dt: dt,
    parameterData: returnArray,
    scaleIndex: scaleindex
  };
}

/**
 * @param {Date} dt
 * @param {Map<string, number>} specialDaysMap
 */
export function getDaytype(dt, specialDaysMap) {
  const key = getShortDate(dt);
  const daytype = specialDaysMap.get(key);
  if (daytype === undefined)
    return 0;

  return daytype;
}

/* calculate avg for hisoric data */
/**
 * @param {string | number} acgr
 * @param {Date} dt
 * @param {number} occupancyFte
 * @param {Map<string, { acgr: number; dt: Date; ahtData: number[]; nocData: number[]; }>} historicDataMap
 */
function getDayData(acgr, dt, occupancyFte,historicDataMap) {
  let key = acgr + '_' + getShortDate(dt);
  if (historicDataMap.has(key)) {
    let x = historicDataMap.get(key);
    let noc = 0;
    let ht = 0;
    for (let i = 0; i <= 95; i++) {
      noc += x.nocData[i];
      ht += x.ahtData[i] * x.nocData[i];
    }
    return { weekdayindex: dt.getDay(), dt: dt, fte: ht / (96*900*(occupancyFte/100)), ffte: 0, noc: parseInt(noc), fnoc: 0, aht: (noc>0)?(ht / noc):0, faht: 0, nocRms: 0, isChecked: false };
  } else {
    return { weekdayindex: dt.getDay(), dt: dt, fte: 0, ffte: 0, noc: 0, fnoc: 0, aht: 0, faht: 0, nocRms: 0, isChecked: false };
  }
}

/**
 * @param {Map<number, {week:string, weekdata:any[]}[]>} callStatsWeekMap
 * @param {{ selectedCallGroups: {id:number}[]; focusedDaytyp: { id: number; system: boolean; }; }} forecastOptions
 * @param {Map<string, number>} specialDaysMap
 */
export function getCallStatsWeek(callStatsWeekMap, forecastOptions, specialDaysMap) {
  if (callStatsWeekMap) {
    const callGroups = forecastOptions.selectedCallGroups;
    let returnArray = [];
    if (callGroups.length > 0) {
      const numberOfRows = callStatsWeekMap.get(callGroups[0].id).filter(isValidWeekRow).length;

      if (callGroups.length == 1) {
        returnArray = callStatsWeekMap.get(callGroups[0].id).filter(isValidWeekRow);
      } else {
        for (let j = 0; j < numberOfRows; j++) {
          let weekdata = [];
          for (let d = 0; d <= 6; d++) {
            weekdata.push(getEmptyDayData(d))
          }
          returnArray.push({ week: '', weekdata: weekdata });
        }
        for (let j = 0; j < numberOfRows; j++) {
          for (let i = 0; i < callGroups.length; i++) {
            const callStatWeekData = callStatsWeekMap.get(callGroups[i].id).filter(isValidWeekRow);
            for (let d = 0; d <= 6; d++) {
              returnArray[j].weekdata[d] = addWeeks(returnArray[j].weekdata[d], callStatWeekData[j].weekdata[d]);
            }
          }

          returnArray[j].week = callStatsWeekMap.get(callGroups[0].id).filter(isValidWeekRow)[j].week
        }
      }

      return returnArray;
    } else {
      return [];
    }
  }

  function isValidWeekRow(item) {
    let returnValue = false;
    for (let i = 0; i < 7; i++) {
      if (item.weekdata[i].dt != null) {
        if (forecastOptions.focusedDaytyp.id == specialDaysMap.get(getShortDate(item.weekdata[i].dt)) ||
          forecastOptions.focusedDaytyp.system) {
          returnValue = true;
        }
      }
    }
    return returnValue;
  }
  function addWeeks(item1, item2) {
    /* TODO Skulle det gå snabbare om man inte skapade ett nytt objekt? */
    return {
      weekdayindex: item1.weekdayindex,
      dt: item2.dt,
      fte: item1.fte + item2.fte,
      ffte: item1.ffte + item2.ffte,
      noc: item1.noc + item2.noc,
      fnoc: item1.fnoc + item2.fnoc,
      aht: item1.noc + item2.noc > 0 ? (item1.noc * item1.aht + item2.aht * item2.noc) / (item1.noc + item2.noc) : 0,
      faht: item1.fnoc + item2.fnoc > 0 ? (item1.fnoc * item1.faht + item2.faht * item2.fnoc) / (item1.fnoc + item2.fnoc) : 0,
      nocRms: item1.nocRms + item2.nocRms,
      isChecked: false
    };
  }
  function getEmptyDayData(weekdayInedx) {
    return {
      weekdayindex: weekdayInedx,
      dt: null,
      fte: 0,
      ffte: 0,
      noc: 0,
      fnoc: 0,
      aht: 0,
      faht: 0,
      nocRms: 0,
      isChecked: false
    };
  }
}