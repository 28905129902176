import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { ModelObject } from '@/model/dn-model-object';
import { fromISODate, toISODate } from '@/js/dn-helper';

/**
 * @typedef {{ id: number; empid?: string; ccid: number; name: string; agreedWorkWeek?:number|null, minWorkWeek?:number|null, maxWorkWeek?:number|null, optimizationOrder?:number,
* email?: string; notify?:boolean, switchid?: string; rotations?: number; skills?: number[]; active?: boolean; tagIds?: number[]; userid?: number|null; password?:string,
* maxDaysInRow?:number|null, maxWeekendsInRow?:number|null, nightRest?:number|null, address1?:string|null, address2?:string|null, address3?:string|null, agreedSms?:boolean|null, phoneNum?:string|null,
* ghost?:boolean; username?:string|null; birthDate?:string|null; hireDate?:string|null; terminationDate?:string|null; note?:string|null, maxBOWeek?:number|null;absenceDuration?:number|null
* }} EmployeeDto
*/

/**
* @typedef {{employeeId:number; added:number[]; removed:number[]}} EmployeeSkillDto
*/

export class Employee extends ModelObject {
  /**
   * @param {EmployeeDto} dto
   */
  constructor(dto) {
    super(dto.id);
    /** @private @type {string|null} */
    this._empid = dto.empid ? dto.empid : null;
    /** @private @type {number|null} */
    this._ccid = dto.ccid;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {number|null} */
    this._agreedWorkWeek = dto.agreedWorkWeek ? dto.agreedWorkWeek : null;
    /** @private @type {number|null} */
    this._minWorkWeek = dto.minWorkWeek ? dto.minWorkWeek : null;
    /** @private @type {number|null} */
    this._maxWorkWeek = dto.maxWorkWeek ? dto.maxWorkWeek : null;
    /** @private @type {number} */
    this._optimizationOrder = dto.optimizationOrder ? dto.optimizationOrder : 0;
    /** @private @type {number|null} */
    this._maxDaysInRow = dto.maxDaysInRow ? dto.maxDaysInRow : null;
    /** @private @type {number|null} */
    this._maxWeekendsInRow = dto.maxWeekendsInRow ? dto.maxWeekendsInRow : null;
    /** @private @type {number|null} */
    this._nightRest = dto.nightRest ? dto.nightRest : null;
    /** @private @type {number|null} */
    this._maxBOWeek = dto.maxBOWeek ? dto.maxBOWeek : null;
    /** @private @type {number|null} */
    this._absenceDuration = dto.absenceDuration ? dto.absenceDuration : null;
    /** @private @type {string|null} */
    this._email = dto.email ? dto.email : null;
    /** @private @type {string|null} */
    this._username = dto.username ? dto.username : null;
    let switchid = dto.switchid ? dto.switchid : null;
    if (switchid !== null) {
      switchid = switchid.trim();
      if (switchid === '') {
        switchid = null;
      }
    }

    /** @private @type {string|null} */
    this._switchid = switchid;
    /** @private @type {number[]} */
    this._skills = dto.skills ? dto.skills : [];
    /** @private @type {boolean|null} */
    this._active = dto.active !== false;
    /** @private @type {string} */
    this._password = '';
    /** @private @type {number[]} */
    this._tagIds = dto.tagIds ? dto.tagIds : [];
    /** @private @type {string|null} */
    this._address1 = dto.address1 ? dto.address1 : null;
    /** @private @type {string|null} */
    this._address2 = dto.address2 ? dto.address2 : null;
    /** @private @type {string|null} */
    this._address3 = dto.address3 ? dto.address3 : null;
    /** @private @type {boolean|null} */
    this._agreedSms = dto.agreedSms ? dto.agreedSms : null;
    /** @private @type {string|null} */
    this._phoneNum = dto.phoneNum ? dto.phoneNum : null;
    /** @type {number|null|undefined} */
    this.userId = dto.userid;
    /** @type {boolean} */
    this.ghost = dto.ghost ? dto.ghost : false;

    /** @private @type {Date|null} */
    this._birthDate = dto.birthDate ? fromISODate(dto.birthDate) : null;
    /** @private @type {Date|null} */
    this._hireDate = dto.hireDate ? fromISODate(dto.hireDate) : null;
    /** @private @type {Date|null} */
    this._terminationDate = dto.terminationDate ? fromISODate(dto.terminationDate) : null;
    /** @private @type {string|null} */
    this._note = dto.note;

    /** @type {number} */
    this.changeVersion = 0;
    /** @type {boolean} */
    this.notify = false;
    /** @private @type {boolean} */
    this._separateUsername = this.userId && this.email !== this.username;
    /** @private @type {number[]} */
    this._warnings = []
    /** @private @type {boolean} */
    this._checked = false

  }

  clone() {
    const dto = this.toDto();
    dto.skills = this.skills.slice();
    dto.tagIds = this.tagIds.slice();
    return new Employee(dto);
  }

  get birthDate() {
    return this._birthDate;
  }

  set birthDate(newValue) {
    this.setEmpDateValue('_birthDate', newValue);
  }

  get hireDate() {
    return this._hireDate;
  }

  set hireDate(newValue) {
    this.setEmpDateValue('_hireDate', newValue);
  }

  get separateUsername() {
    return this._separateUsername;
  }

  set separateUsername(newValue) {
    this.setEmpValue('_separateUsername', newValue);
  }

  get terminationDate() {
    return this._terminationDate;
  }

  set terminationDate(newValue) {
    this.setEmpDateValue('_terminationDate', newValue);
  }

  get note() {
    return this._note;
  }

  set note(newValue) {
    this.setEmpValue('_note', newValue);
  }

  get empid() {
    return this._empid
  }

  set empid(newValue) {
    if (newValue === '') { newValue = null; }
    this.setEmpValue('_empid', newValue);
  }

  get ccid() {
    return this._ccid
  }

  set ccid(newValue) {
    this.setEmpValue('_ccid', newValue);
  }

  get name() {
    return this._name
  }

  set name(newValue) {
    this.setEmpValue('_name', newValue);
  }

  get agworkhours() {
    return getHours(this.agreedWorkWeek);
  }

  set agworkhours(value) {
    this.setHours('_agreedWorkWeek', value);
  }

  /** agreed work per week in minutes */
  get agreedWorkWeek() {
    if (this.isExtra)
      return null;
    return this._agreedWorkWeek;
  }

  set agreedWorkWeek(value) {
    this.setEmpValue('_agreedWorkWeek', value);
  }

  get minWorkWeek() {
    if (this.isExtra)
      return null;
    return this._minWorkWeek;
  }

  set minWorkWeek(newValue) {
    this.setEmpValue('_minWorkWeek', newValue);
  }

  get minWorkWeekHours() {
    return getHours(this.minWorkWeek);
  }

  set minWorkWeekHours(value) {
    this.setHours('_minWorkWeek', value);
  }

  get maxWorkWeek() {
    return this._maxWorkWeek;
  }

  set maxWorkWeek(newValue) {
    this.setEmpValue('_maxWorkWeek', newValue);
  }

  get maxWorkWeekHours() {
    return getHours(this.maxWorkWeek);
  }

  set maxWorkWeekHours(value) {
    this.setHours('_maxWorkWeek', value);
  }

  get maxBOWeek() {
    return this._maxBOWeek;
  }

  set maxBOWeek(newValue) {
    this.setEmpValue('_maxBOWeek', newValue);
  }

  get maxBOWeekHours() {
    return getHours(this.maxBOWeek);
  }

  /**
   * @param {number} value
   */
  set maxBOWeekHours(value) {
    this.setHours('_maxBOWeek', value);
  }

  get absenceDuration() {
    return this._absenceDuration ? this._absenceDuration : 480;
  }

  set absenceDuration(newValue) {
    this.setEmpValue('_absenceDuration', newValue);
  }

  get absenceDurationHours() {
    return getHours(this._absenceDuration);
  }

  /**
   * @param {number} value
   */
  set absenceDurationHours(value) {
    this.setHours('_absenceDuration', value);
  }

  absenceStart() {
    const dur = this.absenceDuration;
    return Math.max(0, 60 * Math.floor((60 * 12 - dur / 2) / 60));
  }

  get optimizationOrder() {
    if (this.ghost) { return 4; }
    return this._optimizationOrder;
  }

  set optimizationOrder(newValue) {
    this.setEmpValue('_optimizationOrder', newValue);
  }

  get maxDaysInRow() {
    if (this._maxDaysInRow === null) {
      return 4;
    }
    return this._maxDaysInRow;
  }

  set maxDaysInRow(newValue) {
    if (this.maxDaysInRow !== newValue) {
      this.setEmpValue('_maxDaysInRow', newValue);
    }
  }

  get maxWeekendsInRow() {
    return this._maxWeekendsInRow;
  }

  set maxWeekendsInRow(newValue) {
    this.setEmpValue('_maxWeekendsInRow', newValue);
  }

  /**
   * Gets the min night rest in minutes.
   * @returns {number}
   * */
  get nightRest() {
    if (this._nightRest === null) {
      if (this.ghost) { return 15; }
      return 11 * 60;
    }
    return this._nightRest;
  }

  set nightRest(value) {
    if (this.nightRest !== value) {
      this.setEmpValue('_nightRest', value);
    }
  }

  get nightRestHours() {
    return getHours(this.nightRest);
  }

  set nightRestHours(value) {
    this.setHours('_nightRest', value);
  }

  get email() {
    return this._email;
  }

  set email(newValue) {
    if (!newValue) { newValue = null; }
    this.setEmpValue('_email', newValue);
  }

  get username() {
    return this._username;
  }

  set username(newValue) {
    if (!newValue) { newValue = null; }
    this.setEmpValue('_username', newValue);
  }

  get switchid() {
    return this._switchid;
  }

  set switchid(newValue) {
    if (newValue !== null) {
      newValue = newValue.trim();
      if (newValue === '') {
        newValue = null;
      }
    }
    this.setEmpValue('_switchid', newValue);
  }

  get switchIdList() {
    if (this.switchid) {
      return this.switchid.split(';');
    }

    return [];
  }

  set switchIdList(idList) {
    if (idList.length === 0) {
      this.switchid = null;
    } else {
      this._switchid = idList.join(';');
    }
  }

  get skills() {
    return this._skills
  }

  set skills(newValue) {
    this.setEmpNumberArrayValue('_skills', newValue);
  }

  get tagIds() {
    return this._tagIds
  }

  set tagIds(newValue) {
    this.setEmpNumberArrayValue('_tagIds', newValue);
  }

  get active() {
    return this._active
  }

  set active(newValue) {
    this.setEmpValue('_active', newValue);
  }

  get password() {
    return this._password
  }

  set password(newValue) {
    if (newValue === '') { newValue = null; }
    this.setEmpValue('_password', newValue);
  }

  get isExtra() {
    return this.optimizationOrder !== 0;
  }

  set isExtra(newValue) {
    if (newValue) {
      this.optimizationOrder = 1;
    } else {
      this.optimizationOrder = 0;
    }
  }

  get address1() {
    return this._address1;
  }

  set address1(newValue) {
    if (newValue === '') { newValue = null; }
    this.setEmpValue('_address1', newValue);
  }

  get address2() {
    return this._address2;
  }

  set address2(newValue) {
    if (newValue === '') { newValue = null; }
    this.setEmpValue('_address2', newValue);
  }

  get address3() {
    return this._address3;
  }

  set address3(newValue) {
    if (newValue === '') { newValue = null; }
    this.setEmpValue('_address3', newValue);
  }

  get agreedSms() {
    return this._agreedSms;
  }

  set agreedSms(newValue) {
    this.setEmpValue('_agreedSms', newValue);
  }

  get phoneNum() {
    return this._phoneNum;
  }

  set phoneNum(newValue) {
    this.setEmpValue('_phoneNum', newValue);
  }

  set warnings(newValue) {
    this._warnings = newValue
  }
  get warnings() {
    return this._warnings
  }

  set checked(newValue) {
    this._checked = newValue
  }
  get checked() {
    return this._checked
  }

  hasChangesWorkTime() {
    return this.hasChangesPart(['_agreedWorkWeek', '_maxWorkWeek', '_minWorkWeek', '_optimizationOrder', '_maxBOWeek', '_absenceDuration']);
  }

  hasChangesTags() {
    return this.hasChangesPart(['_tagIds']);
  }

  hasChangesWorkDays() {
    return this.hasChangesPart(['_maxDaysInRow', '_maxWeekendsInRow', '_nightRest']);
  }

  hasChangesGeneral() {
    return this.hasChangesPart(['_name', '_empid', '_switchid', '_phoneNum', '_active', '_email', '_username',
      '_password', '_address1', '_address2', '_address3', '_birthDate', '_hireDate', '_separateUsername', '_terminationDate', '_note']);
  }

  /**
   * @param {number[]} skills
   */
  hasSameSkills(skills) {
    if (skills.length !== this.skills.length)
      return false;

    for (const skill of this.skills) {
      if (!skills.includes(skill))
        return false;
    }

    return true;
  }

  /**
   * @param {Employee} other
   */
  hasSameWorkTimes(other) {
    return !this.ghost && !other.ghost && other.agreedWorkWeek === this.agreedWorkWeek &&
      other.maxWorkWeek === this.maxWorkWeek &&
      other.minWorkWeek === this.minWorkWeek &&
      other.optimizationOrder === this.optimizationOrder &&
      other.maxBOWeek === this.maxBOWeek &&
      other._absenceDuration === this._absenceDuration;
  }

  /**
   * @param {Employee} other
   */
  hasSameWorkDaySettings(other) {
    return !this.ghost && !other.ghost && other.maxDaysInRow === this.maxDaysInRow &&
      other.maxWeekendsInRow === this.maxWeekendsInRow &&
      other.nightRest === this.nightRest;
  }

  /**
   * @param {Employee} other
   */
  copyWorkWorkDaySettings(other) {
    this.maxDaysInRow = other.maxDaysInRow;
    this.maxWeekendsInRow = other.maxWeekendsInRow;
    this.nightRest = other.nightRest;
  }

  /**
   * @param {Employee} other
   */
  copyWorkHours(other) {
    this.agreedWorkWeek = other.agreedWorkWeek;
    this.maxWorkWeek = other.maxWorkWeek;
    this.minWorkWeek = other.minWorkWeek;
    this.optimizationOrder = other.optimizationOrder;
    this.maxBOWeek = other.maxBOWeek;
    this.absenceDuration = other._absenceDuration;
  }

  isUsernameOk() {
    return !this.separateUsername || !this.username || this.username.length > 3
  }

  /**
   * @param {number[]} tagIdList
   */
  hasAnyTag(tagIdList) {
    if (tagIdList.length === 0) {
      return true;
    }
    for (const tagId of tagIdList) {
      if (this.tagIds.includes(tagId)) { 
        return true;
      }
    }
    return false;
  }

  /** @returns {EmployeeSkillDto} */
  toEmployeeSkillDto() {
    if (this.originalMap !== undefined) {
      const original = this.originalMap.get('_skills');
      if (original) {
        /** @type {number[]} */
        const originalSkills = original.value;
        const added = [];
        for (const skillId of this.skills) {
          if (!originalSkills.includes(skillId)) {
            added.push(skillId);
          }
        }
        const removed = [];
        for (const skillId of originalSkills) {
          if (!this.skills.includes(skillId)) {
            removed.push(skillId);
          }
        }
        return {employeeId:this.id, added, removed};
      }
    }

    return undefined;
  }

  /**
   * @returns {EmployeeDto}
   */
  toDto(patchSave = false) {
    let username = this.username;
    if (!this.separateUsername) {
      if (this.userId) {
        username = this.email;
      } else {
        username = null;
      }
    }

    const dto = {
      id: this.id, empid: this.empid, ccid: this.ccid, name: this.name,
      agreedWorkWeek: this.agreedWorkWeek, maxBOWeek: this._maxBOWeek, absenceDuration: this._absenceDuration, minWorkWeek: this.minWorkWeek, maxWorkWeek: this.maxWorkWeek, optimizationOrder: this._optimizationOrder,
      maxDaysInRow: this._maxDaysInRow, maxWeekendsInRow: this._maxWeekendsInRow, nightRest: this._nightRest,
      email: this.email, notify: this.notify, switchid: this.switchid,
      active: this.active, password: this.password, tagIds: this.tagIds.slice(), userid: this.userId,
      address1: this._address1, address2: this._address2, address3: this._address3, agreedSms: this.agreedSms, phoneNum: this._phoneNum, ghost: this.ghost, username: username,
      birthDate: toISODate(this.birthDate), hireDate: toISODate(this.hireDate), terminationDate: toISODate(this.terminationDate), note: this.note
    };

    if (!patchSave) { // don't allow saving skills in patch
      dto.skills = this.skills.slice();
    }

    return dto;
  }

  /**
   * @private
   * @param {string} prop
   * @param {Date} value
   */
  setEmpDateValue(prop, value) {
    if (this.setDateValue(prop, value)) {
      this.changeVersion += 1;
    }
  }

  /**
   * @private
   * @param {string} prop
   * @param {string | number | boolean} value
   */
  setEmpValue(prop, value) {
    if (this.setValue(prop, value)) {
      this.changeVersion += 1;
    }
  }

  /**
   * @private
   * @param {string} prop
   * @param {number[]} value
   */
  setEmpNumberArrayValue(prop, value) {
    if (this.setNumberArrayValue(prop, value)) {
      this.changeVersion += 1;
    }
  }

  /**
   * @private
   * @param {string} prop
   * @param {any} value
   */
  setHours(prop, value) {
    let numberValue;
    switch (typeof (value)) {
      case 'number':
        numberValue = value;
        break;
      case 'string':
        numberValue = parseFloat(value);
        break;
      default:
        numberValue = null;
        break;
    }

    if (isNaN(numberValue)) {
      numberValue = null;
    }

    if (numberValue !== null) {
      numberValue *= 60;
    }

    this.setEmpValue(prop, numberValue);
  }

  workTimeOk() {
    if (this.agreedWorkWeek > 0) {
      if (this.minWorkWeek > 0) {
        if (this.minWorkWeek > this.agreedWorkWeek) {
          return false;
        }
      }
      if (this.maxWorkWeek > 0) {
        if (this.maxWorkWeek < this.agreedWorkWeek) {
          return false;
        }
      }
    }
    if (this.minWorkWeek > 0 && this.maxWorkWeek > 0) {
      if (this.minWorkWeek > this.maxWorkWeek) {
        return false;
      }
    }
    return true;
  }
}

export class EmployeeList {
  constructor() {
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {Employee[]} */
    this._list = [];
  }

  get hasChanges() {
    return this._hasChanges;
  }

  get list() {
    return this._list
  }

  /**
   * @param {Employee} employee
   */
  add(employee) {
    employee.setContainer(this);
    const list = this.list.slice();
    const numberOfGhosts = list.filter(x => x.ghost ).length;

    let index =-1
    for(let i=0;i<list.length;i++){
      const emp= list[i]
      if(employee.ghost){
        if(emp.ghost&&emp.name.toLowerCase()>employee.name.toLowerCase()){
          index=i
          break
        }
      }
      else{
        if(emp.name.toLowerCase()>employee.name.toLowerCase()){
          index=i
          break
        }
      }
    }

    if(index>-1){
      list.splice(index,0,employee)
    }
    else{
      if(employee.ghost){
          list.push(employee);
      }else{
          list.splice(list.length-numberOfGhosts,0,employee)
      }
      
    }
    this._list = list  
  }

  /**
   * @param {number} employeeId
   */
  async deleteEmployee(employeeId) {
    await fetchAndCheckJson(`employees/${employeeId}`, 'DELETE');
    const employees = this.list.slice();
    const index = employees.findIndex(x => x.id === employeeId);
    employees.splice(index, 1);
    this._list = employees;
    this.setHasChanged();
  }

  /**
   * @param {number} ccId
   */
  async load(ccId) {
    this._list = await getEmployees(ccId);
    for (const t of this._list) {
      t.setContainer(this)
    }
    this._hasChanges = false;
  }

  /**
   * @param {boolean} hasChanges
   */
  itemHasChanged(hasChanges) {
    if (hasChanges !== this._hasChanges) {
      if (hasChanges) {
        this._hasChanges = true;
      } else {
        this.setHasChanged();
      }
    }
  }

  /**
   * @param {number} id
   */
  getById(id) {
    const employees = this.list;
    let employee = {};
    for (let i = 0; i < employees.length; i++) {
      if (employees[i].id === id) {
        employee = employees[i]
      }
    }
    return employee
  }

  /** @private */
  setHasChanged() {
    this._hasChanges = this._list.some(x => x.hasChanges);
  }
}

/**
 * @param {number} ccId
 */
export function createEmployee(ccId) {
  return new Employee({ id: -1, ccid: ccId, name: "New employee" });
}

/**
 * @param {EmployeeDto} dto
 */
export function employeeFromDto(dto) {
  return new Employee(dto);
}

/**
 * @param {number} ccId
 */
export async function getEmployeeCount(ccId) {
  /** @type {number} */
  const result = await fetchAndCheckJson(`employees?ccId=${ccId}&count=1`, 'GET');
  return result;
}

/**
 * @param {number} contactCenterId
 */
export async function getEmployees(contactCenterId) {
  /** @type {EmployeeDto[]} */
  const rows = await fetchAndCheckJson(`employees?ccId=${contactCenterId}`, 'GET');
  const employees = rows.map(employeeFromDto);
  return employees;
}

/**
 * @param {Employee[]} employees
 */
export async function saveEmployees(employees) {
  let result = [];
  await new Promise((resolve) => {
    let promises = []
    for (let i = 0; i < employees.length; i++) {
      if (employees[i].hasChanges) {
        promises.push(patchEmployee(employees[i]).then(x => result.push(x)));
      }
    }
    Promise.all(promises).then(resolve).catch(error => {
      console.error(error.message)
    });
  });

  return result;
}

/**
 * @param {EmployeeSkillDto[]} data
 */
export async function postEmployeeSkill(data) {
  await fetchAndCheckJson(`employee-skill`, 'POST', data); 
}

/**
 * @param {Employee} employee
 * @param {{ id: string; employeeId: number; }[]} employeeSwitchConflicts
 */
function removeDuplicatedSwitchid(employee, employeeSwitchConflicts) {
  if (employeeSwitchConflicts && employeeSwitchConflicts.length > 0) {
    let switchIdList = employee.switchIdList;
    for (const switchIdConflict of employeeSwitchConflicts) {
      switchIdList = switchIdList.filter(x => x !== switchIdConflict.id);
    }
    employee.switchIdList = switchIdList;
  }
}

/**
 * @param {Employee} employee
 */
async function patchEmployee(employee) {
  /** @type {{id:number, userId:number|null, notifyResult:boolean|null, emailAlreadyInUse:boolean, employeeSwitchConflicts:undefined|{id:string, employeeId:number}[]}} */
  const result = await fetchAndCheckJson(`employees/${employee.id}`, 'PATCH', employee.toDto(true));
  if (result.emailAlreadyInUse)
    return result;

  removeDuplicatedSwitchid(employee, result.employeeSwitchConflicts);
  employee.password = '';
  employee.userId = result.userId;
  employee.confirmChanges();
  return result;
}

/**
 * @param {Employee} employee
 */
export async function postEmployee(employee) {
  /** @type {{id:number, userId:number|null, notifyResult:boolean|null, emailAlreadyInUse:boolean, employeeSwitchConflicts:undefined|{id:string, employeeId:number}[]}} */
  const result = await fetchAndCheckJson('employees', 'POST', employee.toDto());
  if (result.emailAlreadyInUse)
    return result;

  removeDuplicatedSwitchid(employee, result.employeeSwitchConflicts);
  employee.id = result.id;
  employee.userId = result.userId;
  employee.confirmChanges();
  return result;
}

/**
 * @param {number} value
 */
function getHours(value) {
  return value ? value / 60 : null;
}