import { useDataStore } from './dataStore';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays, getQuarterHour, getShortDate, getStartOfDay } from '@/js/dn-helper.js';

/** @typedef {{callGroupId:number;dt:string|Date;calls:number[];callTime:number[];dstQ:number|null;dstShift:number|null}} ForecastPredictionDto*/

/** @typedef {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]}>} ForecastDataMap */

class ForecastAiData {
  constructor() {
    /** @private @type {ForecastDataMap} */
    this._forecastDataMap = new Map();
  }

  get forecastDataMap() {
    return this._forecastDataMap;
  }

  /**
  * @param {{ forecastWeeks: number }} forecastOptions
  */
  async loadAiForecastData(forecastOptions) {
    const msPerQ = 1000 * 60 * 15;
    const dataStore = useDataStore();
    const st = dataStore.dateNow;
    /** @type {ForecastDataMap} */
    const forecastDataMap = new Map();
    const fi = addDays(st, (forecastOptions.forecastWeeks + 1) * 7)
    const forecastPredictions = await getForecastPrediction(st, fi);

    for (const p of forecastPredictions) {
      let dt = new Date(p.dt);
      const startOfDay = getStartOfDay(dt);
      let forecast = getOrCreateForcast(p.callGroupId, startOfDay, forecastDataMap);
      let q = getQuarterHour(dt);
      let i = 0;
      let hasShifted = false;
      while (i < 96) {
        forecast.nocData[q] = p.calls[i];
        forecast.ahtData[q] = p.callTime[i];
        if (p.dstQ && p.dstQ === i && !hasShifted) {
          i += p.dstShift;
          hasShifted = true;
        }
        i += 1;
        dt = new Date(dt.getTime() + msPerQ);
        q = getQuarterHour(dt);
        if (q === 0) {
          forecast = getOrCreateForcast(p.callGroupId, dt, forecastDataMap);
        }
      }
    }

    this._forecastDataMap = forecastDataMap;
    return true;
  }

  reset() {
    this._forecastDataMap = new Map();
  }

}

export const forecastAiData = new ForecastAiData();

/**
 * @param {number} callGroupId
 * @param {Date} dt
 * @param {ForecastDataMap} forecastDataMap
 */
function getOrCreateForcast(callGroupId, dt, forecastDataMap) {
  const key = callGroupId + '_' + getShortDate(dt);
  let forecast = forecastDataMap.get(key);
  if (!forecast) {
    /** @type {number[]} */
    const nocData = new Array(96).fill(0);
    /** @type {number[]} */
    const ahtData = new Array(96).fill(0);
    forecast = { dt, acgr: callGroupId, nocData, ahtData };
    forecastDataMap.set(key, forecast);
  }
  return forecast;
}

/**
 * @param {Date} st
 * @param {Date} fi
 */
async function getForecastPrediction(st, fi) {
  /** @type {ForecastPredictionDto[]} */
  const rows = await fetchAndCheckJson(`forecast-prediction?st=${st.getTime()}&fi=${fi.getTime()}`, 'GET');
  return rows;
}